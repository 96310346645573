<template>
	<div
		v-if="$props.back || $props.breadcrumbs.length > 0"
		class="d-flex flex-wrap justify-between px-md-5 px-sm-3 px-2 pt-2 mb-1 xl:px-0">
		<div class="container-fluid">
			<div class="d-flex justify-content-between">
				<div>
					<h4 class="align-text-middle">
						<router-link
							v-if="$props.back" :to="$props.back"
							class=" link-underline link-underline-opacity-0">
							<i class="bi-arrow-left me-2" />
						</router-link>
					</h4>
				</div>
				<div>
					<!-- Breadcrumbs -->
					<nav v-if="$props.breadcrumbs.length > 0" class="nav mb-3 small" aria-label="Breadcrumb">
						<ul class="nav justify-content-end">
							<li class="nav-item">
								<router-link
									:to="toUrl('/dashboard')"
									class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white link-underline link-underline-opacity-0">
									<i class="bi-speedometer2 mr-3" />
									{{ trans('global.pages.home') }}
								</router-link>
							</li>
							<li
								v-for="(item, index) in $props.breadcrumbs" :key="JSON.stringify(item)"
								:aria-current="index < ($props.breadcrumbs.length -1) ? 'page' : ''">
								<i class="bi-chevron-right" />
								<RouterLink
									v-if="index < ($props.breadcrumbs.length -1)" :to="item.to"
									class="ms-1 text-sm font-medium text-gray-700 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white link-underline link-underline-opacity-0">
									<i :class="item.icon + ' me-2'" />{{ item.name }}
								</RouterLink>
								<span v-else class="ms-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">
									<i :class="item.icon + ' me-2'" />{{ item.name }}
								</span>
							</li>
						</ul>
					</nav>
				</div>
			</div>
		</div>
	</div>

	<slot name="header" />

	<!-- In the case that no title or action info is given -->
	<div
		v-if="!($props.icon || $props.title || $props.badge || ($props.actions.length > 0))"
		class="px-md-5 px-sm-3 px-2 pb-md-5 pb-sm-3 pb-2 xl:px-0">
		<Alert class="m-4" />
		<br>
		<slot name="filters" />
		<div class="grid grid-cols-1">
			<template v-if="isElementLoading">
				<div class="pt-10 pb-6 text-center">
					<Spinner />
				</div>
			</template>
			<slot v-else name="body" />
		</div>
	</div>
	<!-- otherwise work as normal -->
	<div v-else class="p-md-5 p-sm-3 p-2 xl:px-0">
		<Alert class="mb-4" />
		<div class="d-flex flex-wrap justify-between mb-6">
			<div class="container-fluid">
				<div class="d-flex justify-content-between">
					<div class="mb-3">
						<h3 class="bold text-2xl">
							<i v-if="$props.icon" :class="icon + ' me-2'" />{{ $props.title }}
						</h3>
						<small v-if="$props.badge == 'wca'">
							{{ trans("global.labels.wc") }} {{ trans("global.labels.survey") }} <span
								class="badge rounded-pill bg-warning ms-2 small">WCA</span>
						</small>
						<small v-else-if="$props.badge == 'bng'">
							{{ trans("global.labels.bng") }} {{ trans("global.labels.survey") }} <span
								class="badge rounded-pill bg-info ms-2 small">BNG</span>
						</small>
					</div>

					<div v-if="$props.actions.length > 0" class="d-flex items-center">
						<button class="btn  " type="button" data-bs-toggle="dropdown" aria-expanded="false">
							<i class="bi-three-dots" />
						</button>
						<ul class="dropdown-menu p-3">
							<li
								v-for="(action, j) in $props.actions" :key="j" :name="'page-actions-'+action.id"
								class=" p-4 p-md-1">
								<UiButton
									v-if="action.hasOwnProperty('to') && action.to" class="my-2"
									:class="{'mr-3' : j < ($props.actions.length-1)}" :to="action.to"
									:title="action.name" :icon="action.hasOwnProperty('icon') ? action.icon : null"
									:theme="action.hasOwnProperty('theme') ? action.theme : null" :label="action.name"
									:type="action.type" />
								<UiButton
									v-if="action.type=='modal'" class="my-2"
									:class="{'mr-3' : j < ($props.actions.length-1)}"
									:data-bs-target="action.data_bs_target"
									:icon="action.hasOwnProperty('icon') ? action.icon : null"
									:theme="action.hasOwnProperty('theme') ? action.theme : null" :label="action.name"
									:type="action.type" />
								<UiButton
									v-else class="my-2" :class="{'mr-3' : j < ($props.actions.length-1)}"
									:title="action.name" :icon="action.hasOwnProperty('icon') ? action.icon : null"
									:theme="action.hasOwnProperty('theme') ? action.theme : null" :label="action.name"
									:type="action.type" @click="onPageActionClick({action: action})" />
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<slot name="filters" />
		<div class="grid grid-cols-1">
			<template v-if="isElementLoading">
				Loading...
				<div class="pt-10 pb-6 text-center">
					<Spinner />
				</div>
			</template>
			<slot v-else name="body" />
		</div>
	</div>
</template>

<script>
	import { computed, defineComponent } from "vue";
	import { trans } from "@/helpers/i18n";
	import { toUrl } from "@/helpers/routing";
	import UiButton from "@/views/components/input/UiButton.vue";
	import Alert from "@/views/components/Alert";
	import Spinner from "@/views/components/icons/Spinner";
	import { useGlobalStateStore } from "@/stores";
	import { storeToRefs } from "pinia";

	export default defineComponent({
		name: "Page",
		components: { Alert, UiButton, Spinner },
		props: {
			id: {
				type: String,
				default: ""
			},
			title: {
				type: String,
				default: ""
			},
			breadcrumbs: {
				type: Array,
				default: () => []
			},
			actions: {
				type: Array,
				default: () => []
			},
			back: {
				type: String,
				default: ""
			},
			isLoading: {
				type: Boolean,
				default: false
			},
			icon: {
				type: String,
				default: ""
			},
			badge: {
				type: String,
				default: ""
			}
		},
		emits: ["action"],
		setup (props, { emit }) {
			function onPageActionClick (data) {
				emit("action", data);
			}

			const isElementLoading = computed(() => {
				return useGlobalStateStore().loadingElements[props.id] || props.isLoading;
			});

			return {
				trans,
				toUrl,
				onPageActionClick,
				isElementLoading
			};
		}
	});
</script>
