<!-- eslint-disable no-mixed-spaces-and-tabs -->
<template>
	<Page v-if="surveyId == survey.id" :icon="'bi-clipboard'" :title="survey.name || 'Unnamed (please edit woodland survey details to add name)'" :badge="JSON.parse(survey?.survey_data)?.survey_type" :back="page.back" :breadcrumbs="page.breadcrumbs" :actions="page.actions" @action="onAction">
		<template #body>
			<div class="container-fluid">
				<ModalSurveyUpdate :element-id="'updateSurveyModal_'+survey.id" :survey-in="survey"/>
				<SurveySummary
					:gridref="site.gridref || ''"
					:site="site.name"
					:htr="site.higher_tier_ref"
					:sbi="site.single_business_id"
					:wmp="site.woodland_man_plan"
					:survey="survey"
					:details="true"
					:site-id="siteId"
					:estate-id="estateId"
					:client-id="clientId"
				/>
			</div>
		</template>
	</Page>
	<Page v-else>
		<template #body>
			Something went wrong!
		</template>
	</Page>
</template>

<script lang="ts" setup>
	import Page from "@/views/layouts/Page.vue";
	import router from "@/router";

	import { defineComponent, computed, onMounted, ref, reactive } from "vue";
	import { trans } from "@/helpers/i18n";
	import axios from "@/plugins/axios";
	import ModalSurveyUpdate from "@/views/pages/private/dashboard/partials/ModalSurveyUpdate.vue";

	import SurveySummary from "@/views/pages/private/dashboard/surveys/SurveySummary.vue";

	import { useAuthStore } from "@/stores/auth";
	import { usePwaStore } from "@/stores/pwa";
	import { useWoodlandSurveyStore } from "@/stores/woodland";
	import { v4 as uuidv4 } from "uuid";

	import Bugsnag from "@bugsnag/js";

	const props = defineProps<{
		surveyId: string,
		siteId: string,
		estateId: string,
		clientId: string
	}>();

	const authStore = useAuthStore();
	const pwaStore = usePwaStore();
	const woodlandSurveyStore = useWoodlandSurveyStore();

	const client = computed(() => {
		const R = authStore.user.clients.find((x: any) => x.id == props.clientId);
		try {
			if (authStore.user.clients == null || R == null) throw new Error("tried to get clients, but this.user.clients is null");
			return R;
		} catch (error) {
			if (Bugsnag.isStarted()) Bugsnag.notify(error as Error);
			console.log(error);
			authStore.syncCurrentUser();
		}
		return authStore.user.clients.find((x: any) => x.id == props.clientId);
	});
	const estate = computed(() => {
		const R = client.value.estates.find((x: any) => x.id == props.estateId);
		try {
			if (client.value == null || R == null) throw new Error("tried to get estates, but this.user...estates is null");
			return R;
		} catch (error) {
			if (Bugsnag.isStarted()) Bugsnag.notify(error as Error);
			console.log(error);
			authStore.syncCurrentUser();
		}
		return client.value.estates.find((x: any) => x.id == props.estateId);
	});
	const site = computed(() => {
		const R = estate.value.sites.find((x: any) => x.id == props.siteId);
		try {
			if (estate.value == null || R == null) throw new Error("tried to get sites, but this.user...sites is null");
			return R;
		} catch (error) {
			if (Bugsnag.isStarted()) Bugsnag.notify(error as Error);
			console.log(error);
			authStore.syncCurrentUser();
		}
		return estate.value.sites.find((x: any) => x.id == props.siteId);
	});
	const survey = computed(() => {
		const R = site.value.woodland_surveys.find((x: any) => x.id == props.surveyId);
		try {
			if (site.value == null || R == null) throw new Error("tried to get woodland_surveys, but this.user...woodland_surveys is null");
			return R;
		} catch (error) {
			if (Bugsnag.isStarted()) Bugsnag.notify(error as Error);
			authStore.syncCurrentUser();
			console.log(error);
		}
		return site.value.woodland_surveys.find((x: any) => x.id == props.surveyId);
	});

	const page = reactive({
		id: "survey_info_" + survey.value.id,
		back: "/site/" + site.value.id + "/" + estate.value.id + "/" + client.value.id,
		filters: false,
		breadcrumbs: authStore.user.type != "owner" ? [
			{
				name: client.value.name,
				to: "/client/" + client.value.id,
				icon: "bi-person-vcard"

			},
			{
				name: estate.value.name,
				to: "/estate/" + estate.value.id + "/" + client.value.id,
				icon: "bi-map"

			},
			{
				name: site.value.name,
				to: "/site/" + site.value.id + "/" + estate.value.id + "/" + client.value.id,
				icon: "bi-pin-map"

			},
			{
				name: survey.value.name,
				active: true,
				icon: "bi-clipboard"
			}
		] : [
			{
				name: site.value.name,
				to: "/site/" + site.value.id + "/" + estate.value.id + "/" + client.value.id,
				icon: "bi-pin-map"

			},
			{
				name: survey.value.name,
				active: true,
				icon: "bi-clipboard"
			}],
		actions: [
			{
				id: "delete",
				name: "Delete Survey",
				icon: "bi-trash",
				theme: "none",
				type: "slim-button"
			},
			{
				id: "update",
				name: "Edit Details",
				icon: "bi-pencil",
				theme: "none",
				type: "modal",
				data_bs_target: "#updateSurveyModal_" + survey.value.id
			}
		]
	});
	function onAction (data: any) {
		switch (data.action.id) {
		case "delete":
			deleteWoodlandSurvey();
			break;
		}
	}

	async function deleteWoodlandSurvey () {
		if (confirm("Are you sure you want to to delete this woodland survey? All linked plot surveys will be deleted as well.")) {
			woodlandSurveyStore.destroy(survey.value);
			router.push("/site/" + site.value.id + "/" + estate.value.id + "/" + client.value.id);
		}
	}
</script>
