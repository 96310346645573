<template>
	<Page
		:back="page.back"
		:breadcrumbs="page.breadcrumbs"
	>
		<template #header>
			<!-- Header content goes here -->
			<nav class="navbar sticky-top navbar-dark bg-light shadow p-0 bg-body z-100" style="margin-left:calc(0.5 * var(--bs-gutter-x))">
				<div id="myList" class="me-2">
					<ul class="nav nav-pills nav-justified navbar-fixed-top my-border">
						<li id="stage1" class="nav-item" name="stage1">
							<a
								:class="'nav-link my-nav-link-inv align-middle ' + ((pill_name=='preassess') ? 'active' : '')"
								data-bs-toggle="tab"
								aria-controls="preassess"
								href="#preassess"
								@click="select_pill('preassess')">
								Stage 1: Desk phase
								<!-- <span class="badge rounded-pill mx-2" :class="'badge-' + (preassess_valid?3:1)" v-text="' '"/> -->
								<span v-if="preassess_valid" :class="pill_name=='preassess' ? 'score-3' : 'text-white'"><i class="bi-check-square"/></span>
								<span v-else :class="pill_name=='preassess' ? 'score-1' : 'text-white'"><i class="bi-x-square"/></span>
							</a>
						</li>
						<li id="stage2" class="nav-item" name="stage2">
							<a
								:class="'nav-link my-nav-link-inv ' + (props.pill=='menu1' ? 'active' : '')"
								data-bs-toggle="tab" aria-controls="menu1" href="#menu1"
								@click="select_pill('menu1')">
								Stage 2: 10m Plot {{ trans("global.what_we_call_plots_and_walk") }}s
								<!-- <span v-if="plots_valid" :class="pill_name=='menu1' ? 'score-3' : 'text-white'"><i class="bi-check-square"/></span>
								<span v-else :class="pill_name=='menu1' ? 'score-1' : 'text-white'"><i class="bi-x-square"/></span> -->
							</a>
						</li>
						<li id="stage3" class="nav-item" name="stage3">
							<a
								:class="'nav-link my-nav-link-inv ' + (props.pill=='home' ? 'active' : '')"
								data-bs-toggle="tab" aria-controls="home" href="#home"
								@click="select_pill('home')">
								Stage 3: Woodland walk {{ trans("global.what_we_call_plots_and_walk") }}
								<!-- <span class="badge rounded-pill mx-2" :class="'badge-' + (walk_valid?3:1)" v-text="' '"/> -->
								<span v-if="walk_valid" :class="pill_name=='home' ? 'score-3' : 'text-white'"><i class="bi-check-square"/></span>
								<span v-else :class="pill_name=='home' ? 'score-1' : 'text-white'"><i class="bi-x-square"/></span>
							</a>
						</li>
						<li
							v-show="authStore.user.email=='gwyneth@sylva.org.uk'" class="nav-item">
							<a id="map_tab" class="nav-link my-nav-link-inv" data-bs-toggle="tab" href="#maps" name="map_tab">
								Map</a>
						</li>
					</ul>
				</div>
			</nav>
		</template>
		<template #body>
			<!-- {{ survey.name }}:
			<br>
			{{ survey.synced_at }}
			<br>
			{{ survey.updated_at }}
			<br>
			{{ JSON.parse(survey.survey_data) }}
			<br>
			{{ alertStore.messages }} -->
			<!-- Body content goes here -->
			<div class="container-fluid" style="--bs-gutter-x: -3re">
				<div >
					<!-- Nav tabs -->

					<UiButton :theme="'theme'" :label="'Go to '+ trans('global.what_we_call_whole_thing') + ' summary'" class="my-3 w-100" @click="$router.push(page.back)"/>

					<!-- Tab panes -->
					<div class="tab-content">
						<div id="preassess" name="preassess" class="tab-pane container p-0" :class="(!props.pill || props.pill =='preassess') ? 'active' : ''" style="border:none;">
							<br>
							<WSurvey
								:survey-json="preAssessmentSurveyJson"
								:active-survey-id="survey.id+'_pre'"
								:survey-data="preassessment_survey"
								:no-nav="false"
								:no-scroll="false"
								:valid="preassess_valid ? 1 : 0"
								@update-survey="update_preassessment"/>
						</div>
						<div v-if="authStore.user.type=='admin'" id="maps" name="maps" class="tab-pane container">
							<ol-map
								:load-tiles-while-animating="true"
								:load-tiles-while-interacting="true"
								style="height: 400px"
							>
								<ol-view
									ref="view"
									:center="center"
									:rotation="rotation"
									:zoom="zoom"
									:projection="projection"
								/>

								<ol-tile-layer>
									<ol-source-osm cross-origin="anonymous" />
								</ol-tile-layer>

								<ol-webgl-points-layer :styles="webglPointsStyle">
									<ol-source-webglpoints cross-origin="anonymous" :format="geoJson">
										<ol-feature v-for="coordinate,index in coordinates" :key="index">
											<ol-geom-point
												:coordinates="coordinate"
												:projection="projection"
											/>
										</ol-feature>
									</ol-source-webglpoints>
								</ol-webgl-points-layer>
							</ol-map>

						<!-- </template> -->
						</div>

						<div id="home" name="home" class="tab-pane container p-0" :class="props.pill =='home' ? 'active' : ''" style="border:none; ">
							<br>
							<WSurvey
								:survey-json="woodlandSurveyJson"
								:active-survey-id="survey.id+'_walk'"
								:survey-data="walk_survey"
								:no-nav="false"
								:no-scroll="false"
								:valid="walk_valid ? 1 : 0"
								@update-survey="update_walk"/>
						</div>

						<div id="menu1" name="menu1" class="tab-pane container p-0" :class="props.pill =='menu1' ? 'active' : ''" style="border:none;">
							<br>

							<div class="px-2">
								<div class="card card-body mb-3">
									<p>
										You will need to carry out a minimum of <b>5</b> plot {{ trans("global.what_we_call_plots_and_walk") }}s for woodlands smaller than 30ha. For woodlands of 30ha or larger, you will need to carry out a minimum of <b>10</b> plots {{ trans("global.what_we_call_plots_and_walk") }}s.
										{{ survey.type=='wca' ? 'Spread plots through the different habitat types  and areas of younger or more mature woodland' : 'Spread plots to capture variation in the woodland parcel' }}.
									</p>
								</div>
								<div class="card card-body mb-3 text-muted">
									<span class="ms-2">
										<small>
											Current GPS coords:
											<span v-if="isElementLoading" class="ms-2 py-2 text-muted">
												<small><span>Getting location...</span></small>
											</span>
											<span v-else>
												<span v-if="lat==0 && lon == 0">Getting location failed. Click to retry, or enter manually.</span>
												<span v-else>{{ lat.toFixed(3) }}, {{ lon.toFixed(3) }}</span>
											</span>
											<small>
												<span class="m-2 btn btn-secondary"><a href="" type="button" data-bs-toggle="modal" :data-bs-target="'#gps_info'"><i class="bi-info-circle text-white"/></a></span>
												<span class="m-2 btn btn-secondary"><a href="" type="button" @click="getLocation()"><i class="bi-arrow-clockwise text-white"/></a></span>
											</small>
										</small>
									</span>

									<div :id="'gps_info'" class="modal fade" tabindex="-1" :aria-labelledby="'gps_info'+'Label'" aria-hidden="true">
										<div class="modal-dialog">
											<div class="modal-content">
												<div class="modal-header">
													<h5 :id="'gps_info'+'Label'" class="modal-title">
														<span>Automatic GPS collection</span>
													</h5>
													<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
												</div>
												<div class="modal-body">
													<p>Depending on your device and settings, GPS positioning may be recorded automatically. If no data shows here, you will need to use another means of collectiong the GPS location of your plots and enter it manually for each plot {{ trans("global.what_we_call_plots_and_walk") }}.</p>

													<p>
														iOS: If you have previously disabled location services and with to re-enable location sharing, please follow these steps. You will need to change settings in two places, as follows:
													</p>
													<ol>
														<li>Open the <strong>Settings</strong> app on your iPhone.</li>
														<li>Go to <strong>Privacy & Security</strong> > <strong>Location Services</strong>.</li>
														<li>Find and tap on <strong>Safari Websites</strong>.</li>
														<li>Set location access to <strong>While Using the App</strong> or <strong>Always</strong>.</li>
														<li>Finally, you will also need to manually allow location access for the Safari app: go to Settings > Safari > Location and set it to "Ask" or "Allow".</li>
													</ol>
												</div>
												<div class="modal-footer">
													<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
														{{ trans('global.buttons.close') }}
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>

								<button class="py-2 px-5  border rounded-xl mb-3 btn btn-theme" @click="makeNewPlot">
									Add Plot <i class="bi-plus"/>
								</button>
								<div id="plotAccordion" class="accordion">
									<div
										v-for="(plot_survey,index) in survey.plot_surveys.filter((x: any) => x.deleted_at == null).sort((a: PlotSurvey, b: PlotSurvey) => (new Date(a['created_at']) > new Date(b['created_at']) ? 1 : -1))"
										:id="plot_survey.id + '_accordion_item'"
										:key="plot_survey.id"
										class="accordion-item"
										:name="plot_survey.id + '_accordion_item'">
										<PlotSurveyWrapper
											:survey-json="plotSurveyJson"
											:parent-survey="survey"
											:active-survey="plot_survey"
											:index="index"
											:collapsed="((!props.item) && index==0) ? true : !(props.item == (plot_survey.id + '_accordion_item'))">
											<WSurvey
												:survey-json="plotSurveyJson"
												:active-survey-id="plot_survey.id"
												:survey-data="JSON.parse(plot_survey.survey_data)"
												:no-nav="false"
												:no-scroll="false"
												:valid="plot_valid(plot_survey) ? 1 : 0"
												@update-survey="update_plot"/>
											<!-- <PlotSurvey :survey-json="plotSurveyJson" :active-survey="plot_survey" /> -->
										</PlotSurveyWrapper>
									</div>
								</div>

								<br>
								<Modal
									id="nvc_modal_"
									:has-form="false"
									:title="'Help with NVC (National Vegetation Classification) Communities'"
									@submit="update_nvc" @clear="update_nvc" @close="update_nvc">
									<!-- <div :id="'nvc_modal_'" class="modal fade" tabindex="-1" :aria-labelledby="'nvc_modal'" aria-hidden="true">
										<div class="modal-dialog">
											<div class="modal-content">
												<div class="modal-header">
													<h5 :id="'nvc_modal'" class="modal-title">
														NVC help
													</h5>
													<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
												</div>
												<div class="modal-body"> -->
									<WSurvey
										:survey-json="NVCSurveyJson"
										:active-survey-id="'nvc'"
										:survey-data="null"
										:no-nav="true"
										:no-scroll="true"
										:valid="1"
										@update-survey="update_nvc"/>
									<!-- </div> -->
									<!-- <div class="modal-footer"> -->
									<!-- <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
														{{ trans('global.buttons.close') }}
													</button>-->
									<!-- <button type="button" class="btn btn-primary" data-bs-dismiss="modal" ">
														{{ trans('global.buttons.submit') }}
													</button> -->
									<!-- </div> -->
									<!-- </div>
										</div>
									</div> -->
								</modal>
							</div>
						</div>
					</div>
				</div>

				<UiButton :theme="'theme'" :label="'Go to '+ trans('global.what_we_call_whole_thing') + ' summary'" class="mt-3 w-100" @click="$router.push(page.back)"/>
			</div>
		</template>
	</Page>
</template>

<script lang="ts" setup>
	import { onMounted, computed, ref, inject, reactive, watch } from "vue";

	// import "survey-core/defaultV2.min.css";
	import { StylesManager } from "survey-core";
	import WSurvey from "@/views/pages/private/dashboard/surveys/WSurvey.vue";
	import PlotSurveyWrapper from "@/views/pages/private/dashboard/surveys/PlotSurveyWrapper.vue";
	// import PlotSurvey from "@/views/pages/private/dashboard/surveys/PlotSurvey.vue";
	import Spinner from "@/views/components/icons/Spinner.vue";
	import UiButton from "@/views/components/input/UiButton.vue";
	import Page from "@/views/layouts/Page.vue";

	// StylesManager.applyTheme("defaultV2");

	import { preAssessmentSurveyJson } from "@/Components/preAssessmentSurveyJson";
	import { woodlandSurveyJson } from "@/Components/woodlandSurveyJson";
	import { plotSurveyJson } from "@/Components/plotSurveyJson";
	import { NVCSurveyJson } from "@/Components/NVCSurveyJson";

	// import { useSurveyStore } from "@/stores/survey";
	import { v4 as uuidv4 } from "uuid";

	import { useGlobalStateStore } from "@/stores/global";
	import { useAuthStore } from "@/stores/auth";
	import { usePwaStore } from "@/stores/pwa";
	import { useAlertStore } from "@/stores/alert";
	import { usePlotSurveyStore } from "@/stores/plot";
	import { useWoodlandSurveyStore } from "@/stores/woodland";
	import axios from "@/plugins/axios";
	import { trans } from "@/helpers/i18n";
	import Modal from "@/views/components/Modal.vue";
	import UiForm from "@/views/components/UiForm.vue";
	import MasonryWall from "@yeger/vue-masonry-wall";
	import type { PlotSurvey, WoodlandSurvey } from "@/types/survey";

	import { Model } from "survey-core";
	import router from "@/router";

	const items = [50, 75, 75, 100, 50, 50, 75, 150, 125, 175, 50, 100, 125];
	const pwaStore = usePwaStore();
	const authStore = useAuthStore();
	const plotSurveyStore = usePlotSurveyStore();
	const woodlandSurveyStore = useWoodlandSurveyStore();
	const alertStore = useAlertStore();

	const globalStateStore = useGlobalStateStore();
	// const surveyStore = useSurveyStore();

	// const props = defineProps<{
	// }>();
	const props = defineProps<{
		surveyId: string,
		siteId: string,
		estateId: string,
		clientId: string,
		pill: string|null,
		item: string|null
	}>();

	// TODO move to onmounted
	let client = (authStore.user.clients.find((x: any) => x.id == props.clientId));
	let estate = (client.estates.find((x: any) => x.id == props.estateId));
	let site = (estate.sites.find((x: any) => x.id == props.siteId));
	// = (site.woodland_surveys.find((x: any) => x.id == props.siteId));
	const survey = computed({
		get () { return getSurveyFromStore(); },
		set (newval) { setSurveyInStore(newval); }
	});
	// let survey = getSurveyFromStore();// let survey = computed(() => {
	// for (const this_client of authStore.user.clients) {
	// 	for (const this_estate of this_client.estates) {
	// 		for (const this_site of this_estate.sites)  {
	// 			for (const surv of this_site.woodland_surveys)
	// 				if (surv.id == props.surveyId) {
	// 					survey =  surv;
	// 					break;
	// 				}
	// 		}
	// 	}
	// }
	// 	console.log("survey not found");
	// 	return null;
	// });
	// // const plot_surveys = ref(survey.value.plot_surveys.filter((x: any) => x.deleted_at == null).sort((a: PlotSurvey, b: PlotSurvey) => (a["created_at"] > b["created_at"] ? 1 : -1)));
	// watch(() => survey, (newVal) => {
	// 	// 	plot_surveys.value = survey.value.plot_surveys.filter((x: any) => x.deleted_at == null).sort((a: PlotSurvey, b: PlotSurvey) => (a["created_at"] > b["created_at"] ? 1 : -1));
	// 	// console.log("ausd");
	// });
	const pill_name = ref("");
	function select_pill (pill_name_in: string) {
		pill_name.value = pill_name_in;
	}
	function init () {
		pill_name.value = props.pill ? props.pill : "preassess";
	}
	init();

	function plot_valid (p: any) {
		// const psurvey = new Model(plotSurveyJson);
		// const pPrevData = p.survey_data;
		// if (pPrevData) {
		// 	const data = JSON.parse(pPrevData);
		// 	psurvey.data = data;
		// }
		// let plotValid = psurvey.validate();
		// return plotValid;
		return true;
	}
	function getSurveyFromStore () {
		for (const this_client of authStore.user.clients) {
			for (const this_estate of this_client.estates) {
				for (const this_site of this_estate.sites) {
					for (const surv of this_site.woodland_surveys)
						if (surv.id == props.surveyId) {
							return surv;
						}
				}
			}
		}
		return null;
	}
	function setSurveyInStore (woodlandSurvey: WoodlandSurvey) {
		for (const this_client of authStore.user.clients) {
			for (const this_estate of this_client.estates) {
				for (const this_site of this_estate.sites) {
					for (let surv of this_site.woodland_surveys)
						if (surv.id == woodlandSurvey.id) {
							surv = woodlandSurvey;
						}
				}
			}
		}
	}

	// watch(() => preassessment_survey, (newSurveyData: any) =>{
	// 	console.log("preassessment_survey");
	// });
	watch(() => survey, (newSurveyData: any) =>{
		console.log("saw a change to client");
		// recompute consts:

		// client = (authStore.user.clients.find((x: any) => x.id == props.clientId));
		// estate = (client.estates.find((x: any) => x.id == props.estateId));
		// site = (estate.sites.find((x: any) => x.id == props.siteId));
		// survey = site.woodland_surveys.find((x: any) => x.id == props.surveyId);
		// getSurveyFromStore();
	});
	const preassessment_survey = computed({
		get () {
			console.log("getting survey");
			// const survey_obj = JSON.parse(site.woodland_surveys.find((x: any) => x.id == props.surveyId).survey_data);
			const survey_obj = JSON.parse(survey.value.survey_data);
			let pre_survey_data = {
				survey_type: survey_obj?.survey_type,
				habitat_type: survey_obj?.habitat_type,
				parcel: survey_obj?.parcel,
				question6: survey_obj?.question6,
				question7: survey_obj?.question7,
				question14a: survey_obj?.question14a,
				question14b: survey_obj?.question14b,
				question14c: survey_obj?.question14c,
				q7: survey_obj?.q7,
				q6: survey_obj?.q6,
				q14: survey_obj?.q14,
				q14_broadleaf: survey_obj?.q14_broadleaf,
				q14_conifer: survey_obj?.q14_conifer
			};
			return pre_survey_data;
		},
		set (newval) {
			console.log("setting survey");
			const survey_obj = JSON.parse(survey.value.survey_data);
			survey_obj.survey_type = newval?.survey_type;
			survey_obj.habitat_type = newval?.habitat_type;
			survey_obj.survey_obj.parcel = newval?.parcel;
			survey_obj.question6 = newval?.question6;
			survey_obj.question7 = newval?.question7;
			survey_obj.question14a = newval?.question14a;
			survey_obj.question14b = newval?.question14b;
			survey_obj.question14c = newval?.question14c;
			survey_obj.q7 = newval?.q7;
			survey_obj.q6 = newval?.q6;
			survey_obj.q14 = newval?.q14;
			survey_obj.q14_broadleaf = newval?.q14_broadleaf;
			survey_obj.q14_conifer = newval?.q14_conifer;
			survey.value.survey_data = JSON.stringify(survey_obj);
			// collateSurveyResults();
		}

	});
	const walk_survey = computed(() => {
		const survey_obj = JSON.parse(site.woodland_surveys.find((x: any) => x.id == props.surveyId).survey_data);
		let walk_survey_data = {
			question1: survey_obj?.question1,
			question3b: survey_obj?.question3b,
			invasives: survey_obj?.invasives,
			question6: survey_obj?.question6,
			question12: survey_obj?.question12,
			innsnotes: survey_obj?.innsnotes,
			question15: survey_obj?.question15,
			survey_notes: survey_obj?.survey_notes,
			q1: survey_obj?.q1,
			q3LR: survey_obj?.q3LR,
			q3O: survey_obj?.q3O,
			q6: survey_obj?.q6,
			q12: survey_obj?.q12,
			q15: survey_obj?.q15
		};
		return walk_survey_data;
	});
	function get_plot_survey (id: string) {
		const r = JSON.parse(getSurveyFromStore()?.plot_surveys.find((x:any) => x.id == id).survey_data);
		return r;
	}
	function collateSurveyResults () {
		// site.woodland_surveys.find((x: any) => x.id == props.surveyId)
		survey.value.survey_data =
			JSON.stringify({ ...preassessment_survey.value, ...walk_survey.value });
		return;
	}
	function update_nvc () {
		console.log("finished nvc");
	}
	async function update_preassessment (survey_data: any, valid: boolean) {
		const survey_obj = JSON.parse(survey.value.survey_data);
		survey_obj.question6 = survey_data.question6;
		survey_obj.question7 = survey_data.question7;
		survey_obj.question14a = survey_data.question14a;
		survey_obj.question14b = survey_data.question14b;
		survey_obj.question14c = survey_data.question14c;
		survey_obj.q7 = survey_data.q7;
		survey_obj.q6 = survey_data.q6;
		survey_obj.q14 = survey_data.q14;
		survey_obj.q14_broadleaf = survey_data.q14_broadleaf;
		survey_obj.q14_conifer = survey_data.q14_conifer;
		site.woodland_surveys.find((x: any) => x.id == props.surveyId).survey_data = JSON.stringify(survey_obj);
		await saveSurveyResults();
		preassess_valid.value = valid;
	}
	async function update_walk (survey_data: any, valid: boolean) {
		const survey_obj = JSON.parse(survey.value.survey_data);
		survey_obj.question1 = survey_data.question1;
		survey_obj.question3b = survey_data.question3b;
		survey_obj.innsnotes = survey_data.innsnotes;
		survey_obj.invasives = survey_data.invasives;
		survey_obj.question6 = survey_data.question6;
		survey_obj.question12 = survey_data.question12;
		// survey_obj.question13 = survey_data.question13;
		survey_obj.question15 = survey_data.question15;
		survey_obj.survey_notes = survey_data.survey_notes;
		survey_obj.q1 = survey_data.q1;
		survey_obj.q3LR = survey_data.q3LR;
		survey_obj.q3O = survey_data.q3O;
		survey_obj.q6 = survey_data.q6;
		survey_obj.q12 = survey_data.q12;
		survey_obj.q15 = survey_data.q15;
		site.woodland_surveys.find((x: any) => x.id == props.surveyId).survey_data = JSON.stringify(survey_obj);
		await saveSurveyResults();
		walk_valid.value = valid;
	}
	async function update_plot (survey_data: any, valid:boolean, id: string) {
		if (!survey.value) return;

		let this_plot_survey = survey.value.plot_surveys.find((x:any) => x.id == id);
		if (this_plot_survey) {
			this_plot_survey.survey_data = JSON.stringify(survey_data);
			await plotSurveyStore.update(this_plot_survey).then(
				(response) => {
					console.log("saveSurveyResults returned: ");
					console.log(response);
					this_plot_survey.synced_at = response.synced_at;
					this_plot_survey.survey_data = response.survey_data;
				})
				.catch((error) => { console.error(error); });
		}

	}

	const invalidPlots = computed(() => {
		let plotsValidated = true;
		let invalidPlotStrings = [];
		if (survey.value.plot_surveys.length > 0) {
			let i = 0;
			for (const p of survey.value.plot_surveys) {
				i++;
				const psurvey = new Model(plotSurveyJson);
				const pPrevData = p.survey_data;
				if (pPrevData) {
					const data = JSON.parse(pPrevData);
					psurvey.data = data;
				}
				let plotValid = psurvey.validate();
				plotsValidated = plotsValidated && plotValid;
				if (!plotValid) invalidPlotStrings.push({ name: (JSON.parse(pPrevData)?.location_descriptor ? " - " + JSON.parse(pPrevData)?.location_descriptor : ""), link: p.id + "_accordion_item", created_at: p.created_at });
			}
		}
		else plotsValidated = false;

		return invalidPlotStrings;

	});

	async function saveSurveyResults () {
		console.log("called save survey results");
		if (!survey.value) return;

		collateSurveyResults();
		// props.activeSurvey.survey_data = JSON.stringify(survey.data);

		await woodlandSurveyStore.update(survey.value).then(
			(response) => {
				console.log("saveSurveyResults returned: ");
				console.log(response);
				survey.value.synced_at = response.synced_at;
				survey.value.survey_data = response.survey_data;

			})
			.catch((error) => { console.error(error); });
	}
	const walk_valid = ref(false);
	const preassess_valid = ref(false);
	const plots_valid = computed(() => {
		return (invalidPlots.value.length == 0);
	});

	const page = reactive({

		id: "survey_info_" + survey.value.id,
		back: "/survey/" + survey.value?.id + "/" + site.id + "/" + estate.id + "/" + client.id,
		filters: false,
		breadcrumbs: authStore.user.type != "owner" ? [
			{
				name: client.name,
				to: "/client/" + client.id,
				icon: "bi-person-vcard"

			},
			{
				name: estate.name,
				to: "/estate/" + estate.id + "/" + client.id,
				icon: "bi-map"

			},
			{
				name: site.name,
				to: "/site/" + site.id + "/" + estate.id + "/" + client.id,
				icon: "bi-pin-map"

			},
			{
				name: survey.value.name,
				active: true,
				icon: "bi-clipboard"
			}
		] : [
			{
				name: site.name,
				to: "/site/" + site.id + "/" + estate.id + "/" + client.id,
				icon: "bi-pin-map"

			},
			{
				name: survey.value.name,
				active: true,
				icon: "bi-clipboard"
			}],
		actions: [
			// {
			// 	id: "delete",
			// 	name: "Delete Survey",
			// 	icon: "bi-trash",
			// 	theme: "none",
			// 	type: "slim-button"
			// },
			// {
			// 	id: "update",
			// 	name: "Edit Details",
			// 	icon: "bi-pencil",
			// 	theme: "none",
			// 	type: "modal",
			// 	data_bs_target: "#updateSurveyModal_" + surveyStore.survey.value.id
			// }
		]
	});

	const lat = computed(() => {
		return position.value.latitude;
	});
	const lon = computed(() => {
		return position.value.longitude;
	});

	const isElementLoading = computed(() => {
		return globalStateStore.loadingElements.plotForm;
	});

	const position = ref({ latitude: 0, longitude: 0 });
	let error:any = ref(null);

	onMounted(() => {
		console.log("mounted surveys.vue");
		getLocation();

		client = (authStore.user.clients.find((x: any) => x.id == props.clientId));
		estate = (client.estates.find((x: any) => x.id == props.estateId));
		site = (estate.sites.find((x: any) => x.id == props.siteId));
		// let survey: WoodlandSurvey;// = (site.woodland_surveys.find((x: any) => x.id == props.siteId));
		// let survey = computed(() => {
		// for (const this_client of authStore.user.clients) {
		// 	for (const this_estate of this_client.estates) {
		// 		for (const this_site of this_estate.sites) {
		// 			for (const surv of this_site.woodland_surveys)
		// 				if (surv.id == props.surveyId) {
		// 					survey.value = surv;
		// 					break;
		// 				}
		// 		}
		// 	}
		// }
	});

	// const projection = ref("EPSG:3857");
	const projection = ref("EPSG:4326");
	const zoom = ref(15);
	const rotation = ref(0);

	const format = inject("ol-format");
	const geoJson = new format.GeoJSON();

	const webglPointsStyle = {
		"circle-radius": 6,
		"circle-fill-color": "yellow",
		"circle-stroke-width": 2,
		"circle-stroke-color": "darkblue",
		"circle-opacity": [
			"interpolate",
			["linear"],
			["get", "population"],
			40000,
			0.6,
			2000000,
			0.92
		]
	};
	const center = computed(() => {
		// todo: replace with woodland centre
		let c_lat = 0;
		let c_lon = 0;
		let n_valid = 0;
		for (const p of survey.value.plot_surveys) {
			if (p.survey_data) {
				const p_survey_data = JSON.parse(p.survey_data);
				c_lon += p_survey_data.question10lon;
				c_lat += p_survey_data.question10lat;
				n_valid++;
			}
		}
		if (n_valid == 0) n_valid = 1;
		console.log("center");
		console.log(c_lat / n_valid);
		console.log(c_lon / n_valid);
		return [c_lon / n_valid, c_lat / n_valid];
	});
	const coordinates = computed(() => {
		let points = [];
		for (const p of survey.value.plot_surveys) {
			if (p.survey_data) {
				const p_survey_data = JSON.parse(p.survey_data);
				points.push([
					p_survey_data.question10lon,
					p_survey_data.question10lat
				]);

			}
		}
		return points;
	});

	function getLocation () {
		if ("geolocation" in navigator) {
			navigator.geolocation.getCurrentPosition(
				(pos) => {
					// Handle success
					console.log("Location access granted:", pos);
					position.value = {
						latitude: pos.coords.latitude,
						longitude: pos.coords.longitude
					};
				},
				(err) => {
					position.value = { latitude: 0, longitude: 0 };
					if (err.code === err.PERMISSION_DENIED) {
						// alert("Location access denied. Please enable location services in Settings.");
						console.error("Location access denied. Please enable location services in Settings.");
						error.value = "Location access denied. Please enable location services in Settings.";
					} else {
						console.error("Error occurred while getting location:", err);
						error.value = "Error occurred while getting location: " + err;
					}
				}
			);
		}
		else {
			console.error("Geolocation is not supported by your browser");
			return;
			// alert("Geolocation is not supported by your browser.");
		}

	}

	async function makeNewPlot () {
		globalStateStore.loadingElements.plotForm = true;
		await getLocation();
		const plot_survey: PlotSurvey = {
			id: uuidv4(),
			created_at: new Date().toISOString().slice(0, 19).replace("T", " "),
			updated_at: new Date().toISOString().slice(0, 19).replace("T", " "),
			woodland_survey_id: survey.value.id,
			survey_data:
				"{\"survey_type\":\"" + JSON.parse(survey.value.survey_data).survey_type + "\"" +
				(position.value.latitude == 0 ? "" : ",\"question10lat\":" + String(position.value.latitude)) +
				(position.value.longitude == 0 ? "" : ",\"question10lon\":" + String(position.value.longitude)) +
				// ",\"question3_1\":-5,\"question3_2\":-5,\"question3_3\":-5,\"question3_4\":-5,\"question3_5\":-5,\"question3_6\":-5,\"question3_7\":-5,\"question3_8\":-5" +
				// ",\"question5\":-5" +
				// ",\"question9a\":-5" +
				",\"question0x\":\"gps\",\"q1\":\"b\",\"q2\":false,\"q3LR\":false,\"q3O\":false,\"q4\":0,\"q5\":0,\"q6\":0,\"q8\":0,\"q10a\":false,\"q10b\":false,\"q11\":0,\"q13\":0,\"result\":0}"

			// survey_data: null
		};
		await plotSurveyStore.store(plot_survey);
		globalStateStore.loadingElements.plotForm = false;
	}

</script>
<style>.grid-item { width: 200px; }
.grid-item--width2 { width: 400px; }</style>
