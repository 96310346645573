<template>
	<!-- Modal -->
	<div v-if="survey.deleted_at" class="d-none"/>
	<div v-else :id="'card_'+survey.id" class="col-xl-3 col-sm-6 col-12 g-4 card-group">
		<div class="card">
			<router-link
				:to="{name:'surveyInfo',params:{ clientId: clientId, estateId: estateId, siteId: siteId, surveyId: survey.id }}"
				class="card-body inactive link-underline link-underline-opacity-0" type="button">
				<div class="media d-flex">
					<div class="media-body text-left">
						<h3><i class="bi-clipboard me-2"/>{{ survey.name || "NONE" }}</h3>
					</div>
				</div>
			</router-link>
			<div class="card-footer" >
				<i class="bi-trash" type="button" @click.prevent="deleteWoodlandSurvey(survey)"/>
				&nbsp;<i class="bi-pencil" type="button" data-bs-toggle="modal" :data-bs-target="'#edit_'+survey.id"/>
				<ModalSurveyUpdate :element-id="'edit_'+survey.id" :survey-in="survey"/>
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
	import AuthService from "@/services/AuthService";
	import { trans } from "@/helpers/i18n";
	import { defineComponent, reactive, onMounted } from "vue";
	import { getResponseError } from "@/helpers/api";
	import { useAuthStore } from "@/stores/auth";
	import { usePwaStore } from "@/stores/pwa";
	import { useWoodlandSurveyStore } from "@/stores/woodland";
	import axios from "@/plugins/axios";

	import ModalSurveyUpdate from "@/views/pages/private/dashboard/partials/ModalSurveyUpdate.vue";

	const authStore = useAuthStore();
	const pwaStore = usePwaStore();
	const woodlandSurveyStore = useWoodlandSurveyStore();

	const props = defineProps<{
		survey:any;
		siteId:any;
		estateId:any;
		clientId:any;
	}>();

	function deleteWoodlandSurvey (woodland_survey: any) {

		if (confirm("Are you sure you want to to delete this woodland survey? All linked plot surveys will be deleted as well.")) {
			woodlandSurveyStore.destroy(woodland_survey);
		}
	}
</script>
