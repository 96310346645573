import axios from "@/plugins/axios";
import BaseService from "@/services/BaseService";
import { useGlobalStateStore } from "@/stores/global";
import type { WoodlandSurvey } from "@/types/survey";
import type { PlotSurvey } from "@/types/survey";

export default class SurveyService extends BaseService {

	private globalStateStore;

	constructor () {
		super();
		this.url = "/";
		this.setupAPI(axios.defaults.baseURL);
		this.globalStateStore = useGlobalStateStore();
	}

	index () {
		return this.get(route("api.woodland-surveys.index"));
	}

	save (payload: any) {
		//         return this.post("/dashboard", payload).finally(() => {
		//             this.globalStateStore.loadingElements['login-form'] = false;
		//         });
	}

	submit () {
		//         return this.post("/dashboard", {}, {}).finally(() => {
		//             this.globalStateStore.loadingElements['logout-form'] = false;
		//         });
	}

	async updateWoodlandSurvey (payload: WoodlandSurvey) {
		return this.patch(route("api.woodland_survey.update", { id: payload.id }), payload);

	}
	async updatePlotSurvey (payload: PlotSurvey) {
		return this.patch(route("api.plot_survey.update", { id: payload.id }), payload);
	}

}
