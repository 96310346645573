<template>
	<Modal
		:id="elementId"
		:has-form="true"
		:title="trans('users.labels.update_survey')"
		@submit="onFormSubmit"
		@clear="onFormClear" @close="onFormClear">
		<UiForm
			:id="elementId+'_form'"
			:type="'update'" :data-bs-dismiss="formValidated" @submit="onFormSubmit()"
			@clear="onFormClear" @close="onFormClear">
			{{ trans("global.phrases.assess_what") }}*
			<div class="form-check">
				<input
					:id="'updateFlexRadioDefault1'+survey.id"
					v-model="form.survey_type"
					value="wca"
					class="form-check-input"
					type="radio"
					:name="'flexRadioDefaultupdate'+survey.id"
					:checked="form.survey_type=='wca'">
				<label class="form-check-label" :for="'updateFlexRadioDefault1'+survey.id">
					{{ trans('global.labels.wc') }}
				</label>
			</div>
			<div class="form-check">
				<input
					:id="'updateFlexRadioDefault2'+survey.id"
					v-model="form.survey_type"
					value="bng"
					class="form-check-input" type="radio" :name="'flexRadioDefaultupdate'+survey.id"
					:checked="form.survey_type=='bng'">
				<label class="form-check-label" :for="'updateFlexRadioDefault2'+survey.id">
					{{ trans('global.labels.bng') }}
				</label>
			</div>

			<div
				v-if="form.survey_type=='bng'">
				<hr>
				{{ trans('global.phrases.select_planning_authority') }}:
				<select
					v-model="form.planning_authority"
					class="form-select"
					:required="false"
					aria-label="Select Planning Authority">
					<option :value="null" />
					<option
						v-for="(char, index) in countiesListBNG"
						:key="index"
						:value="char"
						:disabled="Array.from(char)[0] == '-'">
						{{ char }}
					</option>
				</select>
				{{ trans('global.phrases.select_hab_type') }}:
				<select v-model="form.hab_type" class="form-select" aria-label="Default select example">
					<option v-for="(e,index) in 11" :key="index" :value="String(index)">
						{{ trans('global.report.habitat_type_' + String(index)) }}
					</option>
				</select>

				{{ trans('global.phrases.select_parcel') }}:
				<select v-model="form.parcel" class="form-select" aria-label="next select example">
					<option v-for="(char, index) in 'ABCDEFGHIJKLM'" :key="index" :value="char">
						{{ char }}
					</option>
				</select>

				<TextInput
					v-model="form.parcel_name"
					type="text"
					:required="false"
					:label="'Parcel name (optional)'"
					name="parcel_name" class="mb-2" />
			</div>

			<hr>

			<TextInput
				v-model="form.name"
				type="text"
				:required="true"
				:label="trans('users.labels.survey_name')"
				name="name" class="mb-2" />
			<TextInput
				v-model="form.surveyor_name"
				type="text"
				:required="true"
				:label="trans('users.labels.surveyor_name')" name="name" class="mb-2" />
			<label for="startDate">{{ trans('users.labels.assessment_date') }}</label>
			<input id="startDate" v-model="form.assessment_date" class="form-control" type="date">
			<hr>
			<small>
				<CheckboxInput
					v-model="form.permitPartialShare"
					type="radio"
					:required="false"
					:label="'Government agencies are interested in the strategic monitoring of environmental data that you and other users of the WCA app might provide. Do you agree to sharing full grid reference information with government agencies? Note that no personal data (name or email) will be shared.'"
					name="useage-select" class="mb-4"
					:options="[{id:'1', title:'YES, I agree to share full grid reference information with government agencies. [OPTIONAL]'}]"
					@update:model-value="formUpdatePermitPartialShare" />
				<br>
				<!-- <CheckboxInput
					v-model="form.permitFullShare"
					type="radio"
					:required="false"
					:label="'Do you agree to share accurate location data and key reference numbers with government agencies for official purposes? You may be required to share this data with government agencies to support any official application or engagement.'"
					name="useage-select"
					class="mb-4"
					:options="[{id:'1', title:'YES, I agree to share full grid reference information and key reference numbers with government agencies for official purposes. [REQUIRED IN SOME CIRCUMSTANCES]'}]"
					@update:model-value="formUpdatePermitFullShare"/>
				<br> -->
				<!-- In either case, no personal data (name or email) will be shared. -->
				<a href="/terms" target="_blank">Click here to see the data sharing and management policy.</a>
			</small>
			<br>
		</UiForm>
	</modal>
</template>

<script lang="ts" setup>
	import AuthService from "@/services/AuthService";
	import { trans } from "@/helpers/i18n";
	import { defineComponent, reactive, onMounted, computed, watch } from "vue";
	import { getResponseError } from "@/helpers/api";
	import { useAuthStore } from "@/stores/auth";
	import { usePwaStore } from "@/stores/pwa";
	import { useAlertStore } from "@/stores";
	import UiButton from "@/views/components/input/UiButton.vue";
	import TextInput from "@/views/components/input/TextInput.vue";
	import CheckboxInput from "@/views/components/input/CheckboxInput.vue";
	import Modal from "@/views/components/Modal.vue";
	import UiForm from "@/views/components/UiForm.vue";
	import axios from "@/plugins/axios";
	import { useWoodlandSurveyStore, woodlandSurveyDefaults } from "@/stores/woodland";
	import { countiesListBNG } from "@/stores/site";
	import type { WoodlandSurvey } from "@/types/survey";

	const authService = new AuthService();
	const alertStore = useAlertStore();
	const authStore = useAuthStore();
	const pwaStore = usePwaStore();
	const woodlandSurveyStore = useWoodlandSurveyStore();
	const form = reactive({
		name: "",
		parcel_name: "",
		surveyor_name: "",
		assessment_date: "",
		survey_type: null,
		hab_type: null,
		parcel: null,
		permitFullShare: "1",
		permitPartialShare: "0",
		planning_authority: "null"
	});

	const props = defineProps<{
		elementId:string;
		surveyIn:WoodlandSurvey;
	}>();

	watch(() => props.surveyIn, (newVal) => {
		onFormClear();
	});
	const survey = computed({
		get () { return getSurveyFromStore(); },
		set (newval) { setSurveyInStore(newval); }
	});
	function getSurveyFromStore () {
		for (const this_client of authStore.user.clients) {
			for (const this_estate of this_client.estates) {
				for (const this_site of this_estate.sites) {
					for (const surv of this_site.woodland_surveys)
						if (surv.id == props.surveyIn.id) {
							return surv;
						}
				}
			}
		}
		return null;
	}
	function setSurveyInStore (woodlandSurvey: WoodlandSurvey) {
		for (const this_client of authStore.user.clients) {
			for (const this_estate of this_client.estates) {
				for (const this_site of this_estate.sites) {
					for (let surv of this_site.woodland_surveys)
						if (surv.id == woodlandSurvey.id) {
							surv = woodlandSurvey;
						}
				}
			}
		}
	}

	onMounted(() => {
		if (!authStore.user) {
			return;
		}
		form.name = survey.value.name;
		form.surveyor_name = survey.value.surveyor_name;
		form.assessment_date = survey.value.assessment_date ? survey.value.assessment_date.substring(0, 10) : new Date().toISOString().substring(0, 10);
		form.survey_type = JSON.parse(survey.value.survey_data)["survey_type"];
		form.hab_type = JSON.parse(survey.value.survey_data)["habitat_type"];
		form.parcel = JSON.parse(survey.value.survey_data)["parcel"];
		form.parcel_name = JSON.parse(survey.value.survey_data)["parcel_name"];
		form.planning_authority = survey.value.planning_authority;
		form.permitFullShare = String(survey.value.permit_full_share);
		form.permitPartialShare = (form.permitFullShare == "1" ? "0" : String(survey.value.permit_partial_share));
	});

	async function onFormClear () {
		form.name = survey.value.name;
		form.surveyor_name = survey.value.surveyor_name;
		form.assessment_date = survey.value.assessment_date.substring(0, 10);
		form.survey_type = JSON.parse(survey.value.survey_data)["survey_type"];
		form.hab_type = JSON.parse(survey.value.survey_data)["habitat_type"];
		form.parcel_name = JSON.parse(survey.value.survey_data)["parcel_name"];
		form.parcel = JSON.parse(survey.value.survey_data)["parcel"];
		form.permitFullShare = String(survey.value.permit_full_share);
		form.permitPartialShare = (form.permitFullShare == "1" ? "0" : String(survey.value.permit_partial_share));
		form.planning_authority = survey.value.planning_authority;
	}

	function validateForm () {
		return (!(form.name == "" ||
			form.surveyor_name == "" ||
			form.assessment_date == null ||
			form.survey_type == null ||
			(form.survey_type == "bng" && (form.hab_type == null || form.parcel == null))));
	}
	const formValidated = computed (() => {
		return validateForm();
	});
	function formUpdatePermitFullShare (value: any, checked: boolean) {
		form.permitFullShare = checked ? "1" : "0";
		form.permitPartialShare = checked ? "0" : form.permitPartialShare;
	}
	function formUpdatePermitPartialShare (value: any, checked: boolean) {
		form.permitPartialShare = checked ? "1" : "0";
		form.permitFullShare = checked ? "0" : form.permitFullShare;
	}

	async function onFormSubmit () {
		if (formValidated.value) {
			survey.value.name = form.name;
			survey.value.surveyor_name = form.surveyor_name;
			survey.value.assessment_date = form.assessment_date;
			survey.value.updated_at = new Date().toISOString().replace("T", " ");
			survey.value.permit_full_share = form.permitFullShare == "1" ? 1 : 0;
			survey.value.permit_partial_share = form.permitPartialShare == "1" ? 1 : 0;
			survey.value.planning_authority = form.planning_authority ? form.planning_authority : "null";
			// make sure survey type is propogated to existing plots for this assessment:
			if (survey.value.plot_surveys)
				for (const plot of survey.value.plot_surveys) {
					let psd = JSON.parse(plot.survey_data);
					psd.survey_type = form.survey_type;
					plot.survey_data = JSON.stringify(psd);
				}
			survey.value["_method"] = "patch";

			let survey_data = JSON.parse(survey.value.survey_data);
			survey_data.survey_type = form.survey_type;
			survey_data.habitat_type = form.hab_type;
			survey_data.parcel = form.parcel;
			survey_data.parcel_name = form.parcel_name;
			survey.value.survey_data = JSON.stringify(survey_data);
			console.log(survey.value.synced_at);

			await woodlandSurveyStore.update({ ...woodlandSurveyDefaults, ...survey.value })
				.then((response) => {
					console.log("response");
					console.log(response);
					setSurveyInStore(response);
					survey.value.name = response.name;
					survey.value.synced_at = response.synced_at;

					survey.value.surveyor_name = response.surveyor_name;
					survey.value.assessment_date = response.assessment_date;
					survey.value.updated_at = response.updated_at;
					survey.value.permit_full_share = response.permit_full_share;
					survey.value.permit_partial_share = response.permit_partial_share;
					survey.value.planning_authority = response.planning_authority;
					// make sure survey type is propogated to existing plots for this assessment:
					if (survey.value.plot_surveys)
						for (const plot of survey.value.plot_surveys) {
							let psd = JSON.parse(plot.survey_data);
							psd.survey_type = form.survey_type;
							plot.survey_data = JSON.stringify(psd);
						}

					let survey_data = JSON.parse(response.survey_data);
					survey.value.survey_data = JSON.stringify(survey_data);
				}).catch(()=> { pwaStore.requireSync = true; });
			console.log(survey.value.synced_at);
			// const now = new Date();
			// survey.value.updated_at = now.toISOString().replace("T", " ");
			// console.log(survey.value.updated_at);

			onFormClear();
		}
	}

</script>
