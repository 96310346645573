import axios from "@/plugins/axios";
import type { AxiosInstance } from "axios";
import router from "@/router";

import { useAuthStore } from "../stores/auth";
import { usePwaStore } from "../stores/pwa";
import { useAlertStore } from "../stores/alert";
export default abstract class BaseService {
	constructor () {
		this.url = "";
	}

	api!: AxiosInstance;
	url: string;

	setupAPI (baseURL: any) {
		const authStore = useAuthStore();
		const pwaStore = usePwaStore();
		const alertStore = useAlertStore();

		axios.interceptors.response.use(
			response => {
				// Return response if it's successful
				return response;
			},
			error => {
				if (error.response && error.response.status === 419) {
					// Session expired, call getCurrentUser
					console.warn("Session expired, refreshing");
					authStore.getCurrentUser();
					pwaStore.requireSync = true;
					// alert("Session expired, refreshing");
					// alertStore.info("Session expired, refreshing");

					// Return rejected promise to propagate the error further if necessary
					// return Promise.reject(error);
				}
				// else if (error.response && error.response.status === 401) {
				// 	router.push("/login");
				// 	throw (error);
				// }
			}
		);
		this.api = axios;
		this.api.defaults.baseURL = baseURL;
		this.api.defaults.withCredentials = true;
	}

	protected post (url: any, data: any, config = {}) {
		return this.api.post(url, data, config);
	}

	protected put (url: any, data: any, config = {}) {
		if (data instanceof FormData) {
			data.append("_method", "PUT");
		} else {
			data._method = "PUT";
		}
		return this.api.post(url, data, config);
	}

	protected patch (url: any, data: any, config = {}) {
		if (data instanceof FormData) {
			data.append("_method", "PATCH");
		} else {
			data._method = "PATCH";
		}
		return this.api.post(url, data, config);
	}

	protected get (url: any, config = {}) {
		return this.api.get(url, config);
	}

	protected delete (url: any, config = {}) {
		return this.api.delete(url, config);
	}

	protected head (url: string): Promise<unknown> {
		return this.api.head(url);
	}
}

